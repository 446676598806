.directions {
    display: flex;
    flex-direction: column;
}
.directions__heading-top {
    display: flex;
    position: relative;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 125.19%;
    color: #000000;
    margin: 185px auto 0;
}
.directions__heading-top-border-img {
    position: absolute;
    top: -8px;
    left: -28px;
    width: 556px;
    height: 70px;
}
.directions__heading {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 125.19%;
    color: #000000;
    margin: 0 auto;
}
.directions__description {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 159.19%;
    text-align: center;
    color: #000000;
    margin: 32px auto 0;
    max-width: 635px;
}
.directions__services-container {
    display: flex;
    margin: 64px auto 0;
    width: 1600px;
}
.directions__services-left-container {
    display: flex;
    flex-direction: column;
    margin: 0 85px 0 0;
}
.directions__services-right-container {
    display: flex;
    flex-direction: column;
    margin: 0;
}
.directions__services-item-container {
    display: flex;
    align-items: center;
    margin: 45px 0 0 0;
}
.directions__services-item-container:first-of-type {
    margin-top: 0;
}
.directions__services-item-icon {
    margin: 0 30px 0 0;
}
.directions__services-item-text {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 131.69%;
    color: #000000;
    margin: 0 auto 0 0;
}
@media screen and (max-width: 1740px) {
    .directions__services-container {
        flex-direction: column;
        margin: 39px auto 0;
        width: auto;
    }
    .directions__services-left-container {
        margin: 0;
    }
    .directions__services-right-container {
        margin-top: 30px;
    }
    .directions__services-item-container {
        margin: 30px 0 0 0;
    }
    .directions__services-item-text {
        font-size: 16px;
        line-height: 21px;
        max-width: 452px;
    }
}
@media screen and (max-width: 900px) {
    .directions__heading-top {
        font-size: 20px;
        line-height: 25px;
        margin: 69px auto 0;
    }
    .directions__heading-top-border-img {
        top: -8px;
        left: -8px;
        width: 256px;
        height: 40px;
    }
    .directions__heading {
        font-size: 20px;
        line-height: 25px;
    }
    .directions__description {
        font-size: 18px;
        line-height: 29px;
        margin: 15px auto 0;
        max-width: 508px;
    }
    .directions__services-item-icon {
        margin: 0 20px 0 0;
    }
    .directions__services-item-text {
        font-size: 16px;
        line-height: 21px;
    }
}
@media screen and (max-width: 540px) {
    .directions__heading-top {
        margin: 64px auto 0;
    }
    .directions__description {
        max-width: 259px;
        text-align: left;
    }
    .directions__services-item-icon {
        margin: 0 10px 0 0;
    }
    .directions__services-item-text {
        max-width: 207px;
    }
    .directions__services-item-icon {
        width: 35px;
        height: 44px;
    }
}