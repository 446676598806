.footer {
    background: #2D2D2D;   
    display: flex;
    border-top: 2px solid #FFFFFF;
    margin: 170px 50px 0;
}
.footer__nav-container {
    display: flex;
    width: 650px;
    margin: 0 auto 0 0;
}
.footer__heading {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 159.19%;
    color: #4BCD9E;
    margin: 39px auto auto 0;
}
.footer__menu {
    margin: 39px auto 0 0;
    display: flex;
    flex-direction: column;
}
.footer__menu-text {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 159.19%;
    color: #4BCD9E;
    margin: 0 auto 0 0;
}
.footer__menu-link {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 18px auto 0 0;
    cursor: pointer;
}
.footer__menu-link:first-of-type {
    margin-top: 22px;
}
.footer__menu-link:hover {
    opacity: .6;
}
.footer__contacts {
    margin: 39px auto 135px 0;
    display: flex;
    flex-direction: column;
}
.footer__contacts-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 159.19%;
    color: #4BCD9E;
    margin: 0 auto 0 0;
}
.footer__contacts-item-container {
    display: flex;
    margin: 18px auto 0 0;
}
.footer__contacts-item-container:first-of-type {
    margin-top: 22px;
}
.footer__contacts-item-icon {
    margin: 0 22px auto 0;
}
.footer__contacts-item-icon_mail {
    margin-right: 13px;
}
.footer__contacts-item-icon_phone {
    margin-right: 13px;
}
.footer__contacts-item-link {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-decoration: none;
    margin: 0;
    max-width: 292px;
}
.footer__contacts-item-link:hover {
    opacity: .6;
}
@media screen and (max-width: 1100px) {
    .footer__nav-container {
        width: 520px;
    }
}
@media screen and (max-width: 900px) {
    .footer {
        border-top: 1px solid #FFFFFF;
        margin: 100px 30px 0;
    }
    .footer__heading {
        font-size: 14px;
        line-height: 22px;
        margin: 22px auto auto 0;
    }
    .footer__menu {
        margin: 22px auto 0 0;
    }
    .footer__menu-text {
        font-size: 14px;
        line-height: 22px;
    }
    .footer__menu-link {
        font-size: 12px;
        line-height: 14px;
        margin: 10px auto 0 0;
    }
    .footer__menu-link:first-of-type {
        margin-top: 15px;
    }
    .footer__contacts {
        margin: 22px auto 109px 0;
    }
    .footer__contacts-text {
        font-size: 14px;
        line-height: 22px;
    }
    .footer__contacts-item-container {
        margin: 14px auto 0 0;
    }
    .footer__contacts-item-container:first-of-type {
        margin-top: 15px;
    }
    .footer__contacts-item-icon {
        margin: 0 16px auto 0;
    }
    .footer__contacts-item-icon_mail {
        margin-right: 7px;
    }
    .footer__contacts-item-icon_phone {
        margin-right: 7px;
    }
    .footer__contacts-item-link {
        font-size: 12px;
        line-height: 14px;
        max-width: 192px;
    }
}
@media screen and (max-width: 760px) {
    .footer__nav-container {
        width: 380px;
    }
}
@media screen and (max-width: 600px) {
    .footer {
        margin: 80px 30px 0;
        flex-direction: column;
    }
    .footer__heading {
        margin: 12px auto auto 0;
    }
    .footer__menu {
        margin: 12px auto 0 0;
    }
    .footer__menu-link {
        margin: 10px auto 0 0;
    }
    .footer__menu-link:first-of-type {
        margin-top: 15px;
    }
    .footer__contacts {
        margin: 12px auto 109px 0;
    }
    .footer__contacts-item-link {
        max-width: 133px;
    }
    .footer__contacts-item-icon {
        width: 16px;
        height: 21px;
        margin-right: 10px;
    }
    .footer__nav-container {
        margin-top: 25px;
        width: 260px;
    }
}