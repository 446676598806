.about-us {
}
.about-us__main-container {
    display: flex;
    width: 1614px;
    margin: 101px auto 0;
}
.about-us__img-container {
    display: flex;
    margin: 0 88px 0 0;
}
.about-us__base-station-img {
    width: 291px;
    height: 579px;
    margin: 0 24px auto 0;
}
.about-us__build-img {
    width: 273px;
    height: 454px;
    margin: 230px 0 0 0;
}
.about-us__info-container {
    display: flex;
    flex-direction: column;
    margin: 108px auto 0 0;
    max-width: 938px;
}
.about-us__heading-container {
    display: flex;
    position: relative;
}
.about-us__heading-top {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 125.19%;
    color: #000000;
    margin: 0 auto 0 0;
}
.about-us__heading-top-img {
    width: 268px;
    height: 70px;
    position: absolute;
    top: -4px;
    left: 81px;
}
.about-us__heading {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 125.19%;
    color: #000000;
    margin: 0 auto 0 0;
}
.about-us__description {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 159.19%;
    color: #000000;
    margin: 36px auto 0 0;
}
.about-us__feedback-link-container {
    display: flex;
    margin: 48px auto 0 0;
}
.about-us__feedback-link-icon {
    width: 65px;
    height: 60px;
    margin: 0 21px 0 0;
    cursor: pointer;
}
.about-us__feedback-link {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #4BCD9E;
    margin: auto auto auto 0;
    cursor: pointer;
}
@media screen and (max-width: 1740px) {
    .about-us__main-container {
        width: 1200px;
    }
    .about-us__info-container {
        margin: 0 auto 0 0;
    }
}
@media screen and (max-width: 1300px) {
    .about-us__main-container {
        width: 508px;
        margin: 68px auto 0;
    }
    .about-us__img-container {
        flex-direction: column;
        margin: 0 31px 0 0;
    }
    .about-us__base-station-img {
        width: 148px;
        height: 245px;
        margin: 0 auto 0 0;
    }
    .about-us__build-img {
        width: 143px;
        height: 217px;
        margin: 36px 0 0 40px;
    }
    .about-us__info-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto 0 0;
        max-width: 303px;
    }
    .about-us__heading-top {
        font-size: 20px;
        line-height: 25px;
    }
    .about-us__heading-top-img {
        width: 128px;
        height: 32px;
        top: -4px;
        left: 38px;
    }
    .about-us__heading {
        font-size: 20px;
        line-height: 25px;
    }
    .about-us__description {
        font-size: 18px;
        line-height: 29px;
        margin: 24px auto 0 0;
    }
    .about-us__feedback-link-container {
        margin: 32px auto 0 0;
    }
    .about-us__feedback-link-icon {
        width: 51px;
        height: 47px;
        margin: 0 14px 0 0;
    }
    .about-us__feedback-link {
        font-size: 14px;
        line-height: 16px;
    }
}
@media screen and (max-width: 540px) {
    .about-us__main-container {
        width: 256px;
        margin: 65px auto 0;
    }
    .about-us__img-container {
       display: none;
    }
    .about-us__info-container {
        margin: 0 auto;
        max-width: 256px;
    }
}
