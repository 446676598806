.form {
    display: flex;
    flex-direction: column;
    width: 565px;
    margin: 50px 0 0 0;
}
.form__input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form__input {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 159.19%;
    border: none; 
    height: 68px;
    margin: 0;
    text-indent: 25px;
}
.form__input::placeholder {
    opacity: .6;
}
.form__input_error {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: red;
    height: 28px;
    margin: 4px auto 0 0;
}
.form__textarea {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 159.19%;
    border: none; 
    resize: none;
    height: 143px;
    text-indent: 25px;
}
.form__textarea::placeholder {
    opacity: .6;
}
.form__agree-container {
    display: flex;
    margin: 12px auto 0 0;
}
.form__agree-vector {
    margin: auto 5px auto 0;
}
.form__agree-text {
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 133.53%;
    color: #FFFFFF;
    margin: auto auto auto 0;
}
.form__button {
    width: 229px;
    height: 57px;
    margin: 50px auto 0 0;
    background: #4BCD9E;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}
.form__button:hover {
    background: #3ba37d;
}
@media screen and (max-width: 900px) {
    .form {
        width: 508px;
        margin: 26px 0 0 0;
    }
    .form__input {
        font-size: 16px;
        line-height: 25px;
        height: 59px;
    }
    .form__input_error {
        font-size: 10px;
        line-height: 14px;
        height: 24px;
    }
    .form__textarea {
        font-size: 16px;
        line-height: 25px;
        height: 126px;
    }
    .form__agree-container {
        margin: 15px auto 0 0;
    }
    .form__agree-text {
        font-size: 10px;
        line-height: 13px;
    }
    .form__button {
        width: 175px;
        height: 42px;
        margin: 36px auto 0 0;
        font-size: 14px;
        line-height: 16px;
    }
}
@media screen and (max-width: 540px) {
    .form {
        width: 257px;
    }
    .form__input {
        height: 45px;
    }
    .form__input_error {
        font-size: 8px;
        line-height: 12px;
        height: 26px;
        margin: 2px auto 0 0;
    }
    .form__textarea {
        height: 116px;
    }
}