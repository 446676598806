.request {
    display: flex;
    background-image: url('../../images/request-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 1000px;
    margin: 220px 0 0;
}
.request__feedback-container {
    display: flex;
    flex-direction: column;
    margin: 96px auto auto 154px;
}
.request__heading {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 125.19%;
    color: #FFFFFF;
    margin: 0 auto 0 0;
    position: relative;
}
.request__border {
    position: absolute;
    top: -7px;
    right: -17px;
    width: 236px;
    height: 70px;
}
.request__description {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 159.19%;
    color: #FFFFFF;
    margin: 32px auto 0 0;
    width: 820px;
}
@media screen and (max-width: 990px) {
    .request__description {
        font-size: 18px;
        line-height: 29px;
        width: 506px;
    }
}
@media screen and (max-width: 900px) {
    .request {
        height: 719px;
        margin: 124px 0 0;
    }
    .request__feedback-container {
        margin: 64px auto 0;
    }
    .request__heading {
        font-size: 20px;
        line-height: 25px;
    }
    .request__border {
        top: -7px;
        right: -5px;
        width: 106px;
        height: 40px;
    }
    .request__description {
        font-size: 18px;
        line-height: 29px;
        margin: 18px auto 0 0;
        width: 506px;
    }
}
@media screen and (max-width: 540px) {
    .request {
        height: 750px;
        margin: 61px 0 0;
        background-position-x: -900px;
    }
    .request__feedback-container {
        margin: 53px auto 0;
    }
    .request__description {
        width: 259px;
    }
}