.advantages {
    background: #2D2D2D;   
    display: flex;
    flex-direction: column;
}
.advantages__heading {
    position: relative;
    margin: 128px auto 0;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 125.19%;
    text-align: center;
    color: #FFFFFF;
}
.advantages__heading-border {
    position: absolute;
    top: -6px;
    left: -18px;
}
.advantages__description {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 159.19%;
    text-align: center;
    color: #FFFFFF;
    margin: 25px auto 0;
    max-width: 827px;
}
.advantages__info-container {
    width: auto;
    margin: 74px auto 0;
    display: flex;
    flex-direction: column;
}
.advantages__info-top-container {
    display: flex;
}
.advantages__info-bottom-container {
    display: flex;
    margin: 62px auto 0;
}
.advantages__info-item-container {
    display: flex;
    margin-right: 60px;
    width: 532px;
    align-items: center;
}
.advantages__info-item-container:last-of-type {
    margin-right: 0;
}
.advantages__info-item-icon {
    width: 65px;
    height: 60px;
    margin: 0 30px 0 0;
}
.advantages__info-item-text {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 159.19%;
    color: #FFFFFF;
    margin: auto auto auto 0;
    max-width: 402px;
}
@media screen and (max-width: 1740px) {
    .advantages__info-top-container {
        flex-direction: column;
    }
    .advantages__info-bottom-container {
        flex-direction: column;
        margin: 26px auto 0;
    }
    .advantages__info-item-container {
        margin-top: 26px;
    }
    .advantages__info-item-container:first-of-type {
        margin-top: 0;
    }
}
@media screen and (max-width: 900px) {
    .advantages__heading {
        margin: 64px auto 0;
        font-size: 20px;
        line-height: 25px;
    }
    .advantages__heading-border {
        width: 159px;
        height: 38px;
        top: -6px;
        left: -7px;
    }
    .advantages__description {
        font-size: 18px;
        line-height: 29px;
        margin: 17px auto 0;
        max-width: 508px;
    }
    .advantages__info-container {
        margin: 20px auto 0;
    }
    .advantages__info-item-container {
        margin-right: auto;
        width: 455px;
    }
    .advantages__info-item-icon {
        margin: 0 19px 0 0;
    }
    .advantages__info-item-text {
        font-size: 18px;
        line-height: 29px;
        max-width: 392px;
    }
}
@media screen and (max-width: 540px) {
    .advantages__heading {
        margin: 48px auto 0;
        max-width: 254px;
        text-align: left;
    }
    .advantages__heading-border {
        left: -7px;
    }
    .advantages__description {
        margin: 15px auto 0;
        max-width: 260px;
        text-align: left;
    }
    .advantages__info-container {
        margin: 15px auto 0;
    }
    .advantages__info-item-container {
        width: 261px;
    }
    .advantages__info-item-text {
        max-width: 177px;
        font-size: 14px;
        line-height: 24px;
    }
}